import { Route, Routes } from 'react-router-dom';
import './App.css';
import PhotoCard from './components/photos/PhotoCard';
import Login from './components/start/Login';
import './css/TopNav.css';
import MapContainer from './MapContainer';

function App() {

  return (
    <Routes>
      <Route path='/tmp' element={<Login></Login>}/>
      <Route path="/" element={<MapContainer></MapContainer>}/>
      <Route path="/photos/:toiletPK/:toiletLoc" element={<PhotoCard></PhotoCard>}/>
    </Routes>
  );
}

export default App;
