import React, {useEffect, useRef, useState} from "react";

import markerRed from './assets/marker-red.png';
import markerOrange from './assets/marker-orange.png';
import markerGreen from './assets/marker-green.png';
import GPS from './assets/gps.png'
import './components/fetchData.js';
import deleteNode from "./deleteNode";
import './Map.css';
import MapToiletCard from "./components/toiletCard/MapToiletCard";
import TopNav from "./TopNav";
import { useLocation, useParams } from "react-router-dom";
import { getDatabase, ref, onValue } from 'firebase/database'
import { initializeApp } from "firebase/app";



const {naver} = window;
const MapContainer = () => {
    const params = useParams();
    const location = useLocation();
    const [data, setData] = useState([]);
    const [pk, setPk] = useState();
    const [gpsLoc, setGeoLocation] = useState([37.32194457569437,126.83082307143813]);
    const gpsRef = useRef([37.32194457569437,126.83082307143813])
    let markers = [];

    let formdataTmp = new FormData();
    let gpsPos = new naver.maps.LatLng(37.32194457569437, 126.83082307143813);
   
    function updateLoc(pos){
        let tmpObj = [pos['_lat'], pos['_lng']]
        gpsRef.current = tmpObj;
        setGeoLocation(gpsRef.current)
    }
    function getPos(){
        navigator.geolocation.getCurrentPosition(function(pos){
            gpsPos = new naver.maps.LatLng(pos.coords.latitude, pos.coords.longitude);
            setGeoLocation(gpsPos);
        })
    }

    let initialize = false

    const config = {
      apiKey: "AIzaSyDteMGJEkmOiLw9wTZd9vnaDlwDsNYebSA",
      authDomain: "knockknock-demo.firebaseapp.com",
      databaseURL: "https://knockknock-demo-default-rtdb.firebaseio.com",
      projectId: "knockknock-demo",
      storageBucket: "knockknock-demo.appspot.com",
      messagingSenderId: "812760733926",
      appId: "1:812760733926:web:7c792fddfe788706898327",
      measurementId: "G-1P5H0G3XRV"
    }
  
    initializeApp(config)
    geoMarker = new naver.maps.Marker({
        position: new naver.maps.LatLng (new naver.maps.LatLng(37.32194457569437, 126.83082307143813)['_lat'],new naver.maps.LatLng(37.32194457569437, 126.83082307143813)['_lng']),
        icon : {
            url: markerGreen,
            size: new naver.maps.Size(34, 34),
            scaledSize: new naver.maps.Size(34, 34),
            origin: new naver.maps.Point(0, 0),
            anchor: new naver.maps.Point(12, 34)
        },
        title : "me"
    });
    const db = getDatabase()
    var geoMarker;
    const getData = () => {
      const data = ref(db, 'Restroom1/') // CHANGE 'chars' TO YOUR DATABASE NAME
      onValue(data, (snapshot) => {
        console.log([snapshot.val()][0]['room1'])
        if([snapshot.val()][0]['room1'] === 1){
            geoMarker.setIcon({
                url: markerRed,
                size: new naver.maps.Size(34, 34),
                scaledSize: new naver.maps.Size(34, 34),
                origin: new naver.maps.Point(0, 0),
                anchor: new naver.maps.Point(12, 34)
            },)
        }else if([snapshot.val()][0]['room1'] === 0){
            geoMarker.setIcon({
                url: markerGreen,
                size: new naver.maps.Size(34, 34),
                scaledSize: new naver.maps.Size(34, 34),
                origin: new naver.maps.Point(0, 0),
                anchor: new naver.maps.Point(12, 34)
            },)
        }
      
      })
    }
    initialize = true;
    useEffect(() => {
        const container = document.getElementById('myMap');
        const options = {
            center: gpsPos,
            level: 3
        };
        var map = new naver.maps.Map(container, options);
        getData()
        geoMarker.setMap(map)
        formdataTmp.append("lat", 37.32194457569437);
        formdataTmp.append("lng", 126.83082307143813);
        formdataTmp.append("radius", "50000");
        naver.maps.Event.addListener(map, 'click', function(){
            document.getElementById('toilet-summary').style.display = 'none';
            deleteNode('toilet-card');
        });
        fetch('https://a8rksepiki.execute-api.ap-northeast-2.amazonaws.com',{
            method : "POST",
            redirect: 'follow',
            headers : {
                // "Content-Type":"application/json; charset=utf-8"                
            },
            body : formdataTmp,
        })
        .then(res => res.json())
        .then(loc => {
            for(let m of loc){
                if(m.hasOwnProperty('availAbility')){
                    let latlng = m['geoJson']['S'].split(',')
                    let marker = new naver.maps.Marker({
                        position: new naver.maps.LatLng (latlng[0], latlng[1]),
                        title : m['PK']['S']
                    });
                    if(m['availAbility']['S'] === 'L') marker.setIcon( {
                        
                        url: markerRed,
                        size: new naver.maps.Size(34, 34),
                        scaledSize: new naver.maps.Size(34, 34),
                        origin: new naver.maps.Point(0, 0),
                        anchor: new naver.maps.Point(12, 34)
                    },)
                    else if(m['availAbility']['S'] === 'M') marker.setIcon({
                        url: markerOrange,
                        size: new naver.maps.Size(34, 34),
                        scaledSize: new naver.maps.Size(34, 34),
                        origin: new naver.maps.Point(0, 0),
                        anchor: new naver.maps.Point(12, 34)
                    },)
                    else if(m['availAbility']['S'] === 'H') marker.setIcon({
                        url: markerGreen,
                        size: new naver.maps.Size(34, 34),
                        scaledSize: new naver.maps.Size(34, 34),
                        origin: new naver.maps.Point(0, 0),
                        anchor: new naver.maps.Point(12, 34)
                    },)    
                    naver.maps.Event.addListener(marker, 'click', function(){
                        map.setCenter(marker.getPosition());
                        try {
                            deleteNode('toilet-card');
                        } catch (error) {
                            
                        }
                        updateLoc(marker.getPosition());
                        setPk(m['PK']['S']);
                        document.getElementById('toilet-summary').style.display = 'block';
                    })
                    marker.setMap(map);
                }
            }
            console.log(loc);
        })
 
    },[initialize]);

    return(
        <>
            {/* <FirebaseConnector></FirebaseConnector> */}
            <TopNav ></TopNav>
            <div id='myMapWrapper'>
                <div id='geolocation-tag'><img id='geolocation-icon'src={GPS}/></div>
                <div id='myMap'></div>
                <MapToiletCard toiletPK= {pk} toiletLoc={gpsRef.current}></MapToiletCard>
            </div>
        </>
    );
}

export default React.memo(MapContainer);